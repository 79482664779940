import Cookies from "js-cookie";
import moment from "moment";
import { IIdentitySegment } from "../api/account/models";
import { IUserProperties } from "../models/account";
import { IUserInfo } from "../models/session";
import SessionReplay from "../plugins/amplitude/sessionReplay";
import AccountSegment from "../plugins/segment/tracking/account";
import Segment from "../plugins/segment/tracking/common";
import Flagsmith from "./flagsmithClass";
import FullStory from "./fullStoryClass";
import NewRelic from "./newRelic";
import Pendo from "./pendoClass";
import { setTokens } from "./token";

export async function initSessionAsync(userInfo: IUserInfo, userProperties?: IUserProperties) {
  FullStory.identify(userInfo);
  Pendo.identify(userInfo, userProperties);
  AccountSegment.trackIdentify(userInfo);
  await Flagsmith.identifyAsync(userInfo);
  Segment.init();
  SessionReplay.init();
  NewRelic.refreshUserId();
}

export async function updateSessionAsync(userInfo: IUserInfo, updateSegment: boolean) {
  FullStory.update(userInfo);
  Pendo.update(userInfo);
  updateSegment && AccountSegment.trackUpdateIdentify(userInfo);
  await Flagsmith.updateAsync(userInfo);
}

export function clearSession() {
  setTokens();
  FullStory.clear();
  SessionReplay.remove();
  Pendo.clear();
  AccountSegment.reset();
  Flagsmith.reset();
  NewRelic.refreshUserId();
  clearShareGalleryClickedAt();
}

export function initialized(value?: true): boolean {
  const initializedValue = "1";
  const initializedCookieName = "zf_mobile_initialized"; // also used in index.html

  if (value != null) {
    Cookies.set(initializedCookieName, initializedValue);

    return value;
  }

  return Cookies.get(initializedCookieName) === initializedValue;
}

function getStorage(local: boolean) {
  return local ? localStorage : sessionStorage;
}

export function getStorageData<T>(key: string, local: boolean = true): T | undefined {
  const data = getStorage(local).getItem(key);
  if (data) {
    try {
      const result = JSON.parse(data);
      if (result != null) {
        return result;
      }
    } catch {
      // ignore parsing exceptions
    }
  }

  return undefined;
}

export function setStorageData(key: string, data: unknown, local: boolean = true) {
  getStorage(local).setItem(key, JSON.stringify(data));
}

export function removeStorageData(key: string, local: boolean = true) {
  getStorage(local).removeItem(key);
}

const loginEventIdStorageKey = "login_event_id";

export function setLoginEventId(eventId: string | undefined) {
  if (eventId) {
    sessionStorage.setItem(loginEventIdStorageKey, eventId);
  }
}

export function getLoginEventId() {
  const eventId = sessionStorage.getItem(loginEventIdStorageKey);
  if (eventId) {
    sessionStorage.removeItem(loginEventIdStorageKey);
  }

  return eventId;
}

const appVersionStorageKey = "app_version";

export function setAppVersion(version: string) {
  localStorage.setItem(appVersionStorageKey, version);
}

export function getAppVersion() {
  return localStorage.getItem(appVersionStorageKey);
}

const userNameStorageKey = "user_name";

export function getUserName() {
  return localStorage.getItem(userNameStorageKey);
}

export function setUserName(userName: string) {
  localStorage.setItem(userNameStorageKey, userName);
}

export function clearUserName() {
  localStorage.removeItem(userNameStorageKey);
}

export function isAddedToHome(identitySegment: IIdentitySegment) {
  return identitySegment.customProperties?.addedToHomeScreen === "true";
}

const shareGalleryClickedAtStorageKey = "share_gallery_clicked_at";

export function checkShareGalleryClickedAt() {
  const value = parseInt(sessionStorage.getItem(shareGalleryClickedAtStorageKey) || "");
  if (isNaN(value)) {
    return false;
  }

  return moment().valueOf() - value <= 5 * 60 * 1000; // 5 minutes
}

export function setShareGalleryClickedAt() {
  sessionStorage.setItem(shareGalleryClickedAtStorageKey, moment().valueOf().toString());
}

function clearShareGalleryClickedAt() {
  sessionStorage.removeItem(shareGalleryClickedAtStorageKey);
}
