import amplitude from "@zenfolio/core-components/dist/utilities/amplitude";
import Flagsmith, { FLAGS } from "../../utilities/flagsmithClass";

const AMPLITUDE_SESSION_REPLAY_API_KEY =
  process.env.REACT_APP_AMPLITUDE_SESSION_REPLAY_API_KEY;
const init = () => {
  if (
    !AMPLITUDE_SESSION_REPLAY_API_KEY ||
    !Flagsmith.isEnabled(FLAGS.ENABLE_AMPLITUDE_SESSION_REPLAY)
  ) {
    return;
  }
  amplitude.init(AMPLITUDE_SESSION_REPLAY_API_KEY);
};
const remove = () => {
  amplitude.remove();
};

const sessionReplay = {
  init,
  remove,
};

export default sessionReplay